import React, { useEffect } from "react";

const CURRENT_VERSION_KEY = "currentBuildVersion";

const VersionChecker = () => {

 const checkForReload = () => {
    if (typeof window === 'undefined') {
      return '';
    }
    const segments = window.location?.pathname.split('/');
    let askForAlert = false;
    if (segments.includes('login')) {
      askForAlert = false;
    } else {
      askForAlert = true
    }
    return askForAlert;
  }

  useEffect(() => {
    const checkVersion = async () => {
      console.log("Checking for new version...");
      try {
        const response = await fetch("/meta.json", { cache: "no-store" });
        const meta = await response.json();
        const newBuildVersion = meta.buildDateTime;
        
        // Retrieve the stored build version from localStorage
        const storedVersion = localStorage.getItem(CURRENT_VERSION_KEY);

        // If there's no stored version, store the current one
        if (!storedVersion) {
          localStorage.setItem(CURRENT_VERSION_KEY, newBuildVersion);
          window.location.reload(true);
        } else if (storedVersion != newBuildVersion) {
          const isAlert = checkForReload();
         if (isAlert) {
            // A new build is available
            if (window.confirm("A new version of the application is available. Refresh now?")) {
              localStorage.setItem(CURRENT_VERSION_KEY, newBuildVersion);
              window.location.reload(true);
            }
          } else {
            localStorage.setItem(CURRENT_VERSION_KEY, newBuildVersion);
            window.location.reload(true);
          }

        }
      } catch (error) {
        console.error("Error checking app version:", error);
      }
    };

    // Check version on mount and optionally at intervals
    checkVersion();
    const interval = setInterval(checkVersion, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(interval);
  }, []);

  return null;
};

export default VersionChecker;
