import { fetchConfigureQuestions, removeHiddenObject } from '../../api/api';

export const MapFamilyQuestion = async (org_type, id, config_type, sub_config_type, fmlBasicRequirmentList) => {
    if (config_type !== 2 && org_type === 3) {
        let response = await fetchConfigureQuestions(id, 2, 1);
        
        let finalResponse = response.response ?  removeHiddenObject(response.response) : [];
        
        if(Array.isArray(finalResponse) && finalResponse?.length>0) {
            
            for(let i = 0; i <= finalResponse.length-1; i++ ) {
                fmlBasicRequirmentList.push(finalResponse[i])
            }
        }
    }
    return fmlBasicRequirmentList;
};
